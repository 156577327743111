<template>
  <v-container>
    <v-snackbar
      v-model="snackbar"
      :color="snackbarColor"
      :multi-line="true"
      :timeout="timeout"
    >
      {{ errorText }}
    </v-snackbar>
    <v-card elevation="12" min-height="600">
      <v-card-title>I miei acquisti</v-card-title>
      <v-card-text>
        <v-row v-show="loading" justify="center">
          <v-col cols="2">
            <br />
            <br />
            <br />
            <v-progress-circular
              color="primary"
              indeterminate
              :size="70"
              :width="7"
            />
            <br />
            <br />
            <br />
          </v-col>
        </v-row>
        <v-data-table
          elevation="10"
          :headers="headers"
          :items="purchases"
          item-key="ID"
          :search="search"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-minus',
            nextIcon: 'mdi-plus',
            itemsPerPageText: 'Acquisti per pagina',
            itemsPerPageAllText: 'Tutti gli acquisti',
            itemsPerPageOptions: [10, 25, 50, 100, 500, -1],
            pageText: '',
          }"
        >
          <template v-slot:top>
            <v-text-field
              v-model="search"
              label="Filtra..."
              class="mx-4"
              itemsPerPageText="'Acquisti per pagina'"
            />
          </template>
          <template v-slot:no-data>
            <v-sheet> Nessun acquisto </v-sheet>
          </template>
          <template v-slot:no-results>
            <v-sheet> Nessun acquisto </v-sheet>
          </template>
          <template v-slot:item.ID="{ item }">
            <span :class="item.closed ? 'text--disabled' : ''">{{
              item.ID
            }}</span>
          </template>
          <template v-slot:item.description="{ item }">
            <span :class="item.closed ? 'text--disabled' : ''">{{
              item.description
            }}</span>
          </template>
          <template v-slot:item.startedText="{ item }">
            <span :class="item.closed ? 'text--disabled' : ''">{{
              item.startedText
            }}</span>
          </template>
          <template v-slot:item.UserText="{ item }">
            <span :class="item.closed ? 'text--disabled' : ''">{{
              item.UserText
            }}</span>
          </template>
          <template v-slot:item.stateText="{ item }">
            <span :class="item.closed ? 'text--disabled' : ''">{{
              item.stateText
            }}</span>
          </template>
          <template v-slot:item.modifiedText="{ item }">
            <span :class="item.closed ? 'text--disabled' : ''">{{
              item.modifiedText
            }}</span>
          </template>
          <template v-slot:item.competenzaText="{ item }">
            <div
              :class="item.closed ? 'text--disabled pa-1' : 'pa-1'"
              :style="
                'color: #fff; background-color: ' +
                item.fsm.states[
                  item.PurchaseHistories[item.PurchaseHistories.length - 1]
                    .state
                ].meta.color
              "
            >
              {{
                item.fsm.states[
                  item.PurchaseHistories[item.PurchaseHistories.length - 1]
                    .state
                ].meta.responsible
              }}
            </div>
          </template>
          <template v-slot:item.financialOperator="{ item }">
            <span :class="item.closed ? 'text--disabled' : ''">{{
              item.financialOperator
            }}</span>
          </template>
          <template v-slot:item.started="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  :to="'/mypurchase/' + item.ID"
                >
                  <v-icon small> mdi-eye-outline </v-icon>
                </v-btn>
              </template>
              <span>Visualizza</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
// @ is an alias to /src
const moment = require("moment-timezone");

//const axios = require('axios');

export default {
  name: "MyRooms",
  data: () => {
    return {
      purchases: [],
      search: "",
      loading: false,
      snackbar: false,
      snackbarColor: "",
      errorText: "",
      timeout: 5000,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "ID",
          align: "start",
          sortable: true,
          value: "ID",
        },
        {
          text: "Descrizione",
          align: "start",
          sortable: true,
          value: "description",
        },
        {
          text: "Inizio",
          align: "start",
          sortable: true,
          value: "startedText",
        },
        {
          text: "Utente/i",
          align: "start",
          sortable: true,
          value: "UserText",
          // filter: this.filterUsers,
          // filter: (value, search, item) => { console.log(value, search, item); return true; }
        },
        {
          text: "Ultimo aggiornamento",
          align: "start",
          sortable: true,
          value: "modifiedText",
        },
        {
          text: "Stato",
          align: "start",
          sortable: true,
          value: "stateText",
          // value: "PurchaseHistories",
        },
        {
          text: "Competenza",
          align: "start",
          sortable: true,
          value: "competenzaText",
        },
        {
          text: "Operatore amministrativo",
          align: "start",
          sortable: true,
          value: "financialOperator",
        },
        {
          text: "",
          align: "end",
          sortable: false,
          value: "started",
        },
      ];
    },
  },

  created: function () {
    this.loading = true;
    let component = this;
    this.$http
      .get(
        process.env.VUE_APP_PATRIMONIO_API +
          "/users/" +
          this.$store.state.userMatricola +
          "/purchases",
        {
          headers: {
            authorization: "Bearer " + this.$store.state.authJwt,
          },
        }
      )
      .then((response) => {
        component.purchases = [];
        for (let purchase of response.data) {
          let UserText = "";
          let first = true;
          for (let user of purchase.Users) {
            if (!first) {
              UserText += ", ";
            }
            first = false;
            UserText += user.COGNOME + " " + user.NOME;
          }
          purchase.startedText = component.formatDateOnly(purchase.started);
          purchase.modifiedText = this.formatDate(
            purchase.PurchaseHistories[purchase.PurchaseHistories.length - 1]
              .date
          );
          purchase.UserText = UserText;
          purchase.stateText =
            purchase.fsm.states[
              purchase.PurchaseHistories[
                purchase.PurchaseHistories.length - 1
              ].state
            ].meta.fullStateName;
          purchase.competenzaText =
            purchase.fsm.states[
              purchase.PurchaseHistories[
                purchase.PurchaseHistories.length - 1
              ].state
            ].meta.responsible;
          if (purchase.financialAssignee) {
            purchase.financialOperator =
              purchase.financialAssignee.COGNOME +
              " " +
              purchase.financialAssignee.NOME;
          }
          component.purchases.push(purchase);
        }

        component.purchases = response.data;
        component.loading = false;
      })
      .catch((err) => {
        if (err.response) {
          this.errorText = err.response.statusText;
          this.snackbarColor = "red";
          this.snackbar = true;
        } else if (err.request) {
          this.errorText = "Errore in caricamento acquisti";
          this.snackbarColor = "red";
          this.snackbar = true;
        } else {
          console.log(err);
        }
        this.loading = false;
      });
  },
  methods: {
    formatDate(date) {
      return moment(date)
        .tz("Europe/Rome")
        .locale("IT")
        .format("D MMMM YYYY, HH:MM:ss");
    },
    formatDateOnly(date) {
      return moment(date).tz("Europe/Rome").locale("IT").format("D MMM YYYY");
    },
  },
};
</script>